<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        fixed-header
        disable-sort
        disable-pagination
        hide-default-footer
        class="title" 
        loader-height="1"
        :loading="loading"
        :headers="headers" 
        :items="products.data"
      >
        <template v-slot:item.brand="{ item }">
          {{ item.brand.name }}
        </template>

        <template v-slot:item.metric="{ item }">
          {{ item.metric.unit }} ({{ item.metric.symbol }})
        </template>
        <!-- <template v-slot:item.description="{ item }">
          <v-menu
            open-on-hover
            right
            offset-x
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <div 
                class="text-truncate" 
                style="max-width: 250px"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.description }}
              </div>
            </template>

            <v-card color="secondary lighten-1">
              <v-card-text>
                <p class="ma-0 white--text">
                  {{ item.description }}
                </p>
              </v-card-text>
            </v-card>
          </v-menu>
        </template> -->
        
        <!-- <template v-slot:item.actions="{ item }">
          <v-btn
            dark
            small
            class="ttn"
            color="primary"
            @click="$emit('edit', item)"
          >
            Edit
          </v-btn>
        </template> -->
      </v-data-table>

      <app-pagination
        v-if="products.meta"
        :meta="products.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      page: 1,
      loading: true,
      headers: [
        { text: 'Product', value: 'description' },
        { text: 'Brand', value: 'brand' },
        { text: 'Metric', value: 'metric' },
      ]
    }
  },

  computed: {
    ...mapGetters({
      products: 'getProducts'
    })
  },

  methods: {
    ...mapActions([
      'setProducts'
    ]),

    loadProducts () {
      this.setProducts({
        params: {
          page: this.page
        }
      }).then(() => {
        this.loading = false
      })
    },

    pageChanged (page) {
      this.page = page
      this.loading = true
      this.loadProducts()
    },
  },

  mounted () {
    this.loadProducts()
  }
}
</script>